<script>
    import { Card, instance as StripeInstance } from 'vue-stripe-elements-plus'
    import {mapState} from "vuex";

    export default{
        components: {Card},
        page: {
            title: 'Αγορά athlisis',
            meta: [{ name: 'description' }],
        },

        computed:{
          ...mapState({
            user: state => state.auth.currentUser,
            token: state => state.auth.token
          }),

          invoiceDetailsFilled(){
            if(!this.order.invoice) return true;

            return this.userCompany.VAT && this.userCompany.companyName && this.userCompany.address && this.userCompany.mobile
          }
        },
        data(){
            return{
                price: null,
                salePrice: null,
                showPurchaseForm: false,
                showThankYouForm: false,
                completed: false,
                stripeKey: null,
                stripeOptions: {
                    iconStyle: 'solid',
                    hidePostalCode: true,
                    style: {

                        base: {
                            color: '#32325D',
                            fontWeight: 500,
                            fontFamily: 'inherit',
                            fontSize: '16px',
                            fontSmoothing: 'antialiased',
                            '::placeholder': {
                                color: '#CFD7DF',
                            },
                            ':-webkit-autofill': {
                                color: '#e39f48',
                            },
                        },
                        invalid: {
                            color: '#E25950',

                            '::placeholder': {
                                color: '#FFCCA5',
                            },
                        },
                    },
                },
              userCompany: {
                companyName: '',
                occupation: '',
                address: '',
                VAT: '',
                DOY: '',
                telephone: null,
                mobile:null,
              },
                order:{
                    instalments: 1,
                    submitted: false,
                    couponCode: null,
                    hasCoupon: false,
                    error: '',
                    invoice: true,
                }
            }
        },
        created(){
            this.checkIfPaid();
            if(this.user.isPremium) this.$router.push('/');
        },
        async mounted() {
            this.checkIfPaid();
            if(this.user.isPremium) this.$router.push('/');
        },

        methods:{

            getPrice(){
                this.order.submitted=true;
                this.$axios.get('/users/prices', {params: {couponCode: this.order.couponCode}}).then(result=>{
                    this.order.submitted=false;
                    this.price = result.data.price;
                    this.salePrice = result.data.sale_price;
                    this.stripeKey = result.data.stripeKey;
                    this.order.hasCoupon = result.data.couponEnabled;
                });
            },

            displayPurchaseForm(){
                this.getPrice();
                this.checkIfPaid();
                this.showPurchaseForm = true;
            },

            checkIfPaid(purchaseCompleted = false){
                this.$axios.get('/users/purchase-status').then(result=> {
                    if(result.data.message === 'PURCHASED'){
                        this.$store.dispatch('auth/refetchUser').then(()=>{
                            this.user = this.$store? this.$store.state.auth.currentUser : {};
                            if(this.user.isPremium) this.$router.push('/');
                            if(purchaseCompleted){
                                this.showPurchaseForm = false;
                                this.showThankYouForm = true;
                                this.$router.push({name: 'home', query:{first_time: 'yes'}});
                            }
                        });
                    }
                });
            },

            makePayment(){
                this.order.submitted = true;
                this.order.error = '';

                let orderDetails = {
                    userId: this.user.id,
                    instalments: this.order.instalments,
                    couponCode: this.order.hasCoupon ? this.order.couponCode : null,
                    invoice: this.order.invoice
                };

                this.$axios.post('/users/purchase', {orderDetails: orderDetails, userCompany: this.userCompany},
                    {headers: {common:{"x-access-token": this.token}}}).then(result=> {

                    let clientSecret = result.data.client_secret;
                    StripeInstance.confirmCardPayment(clientSecret, {
                        payment_method: {
                            card: this.$refs.stripeCard.$refs.element._element,
                            billing_details: {
                                name: this.user.firstName + ' ' + this.user.lastName,
                                email: this.user.email
                            }
                        }
                    }).then(result => {
                        if (result.error) {
                            // Show error to your customer (e.g., insufficient funds)
                            this.order.submitted = false;
                            this.order.error = result.error.message;
                            this.order.error += ' Εάν πιστεύετε ότι έχει γίνει λάθος, επικοινωνήστε μαζί μας στο info@athlisis.com';
                        } else {
                            // The payment has been processed!
                            if (result.paymentIntent.status === "succeeded") {
                                this.checkIfPaid(true);
                            }
                        }
                    });
                }).catch(e => {
                    this.order.submitted = false;
                    this.order.error = 'Κάτι πήγε λάθος.';
                    this.order.error += ' Επικοινωνήστε μαζί μας στο info@athlisis.com';
                });
            }
        }
    }
</script>
<style lang="scss">
    #purchase{
        .container-payment{
            label{
                font-weight:normal;
            }
        }

        .card-body{
            @media(max-width:467px){
                padding-left: 0!important;
                padding-right: 0!important;
            }
            .p-3{
                @media(max-width:467px){
                    padding-left: 0!important;
                    padding-right: 0!important;
                }
            }
        }
        .StripeElement {
            box-sizing: border-box;

            height: 40px;

            padding: 10px 12px;

            border: 1px solid transparent;
            border-radius: 4px;
            background-color: white;

            box-shadow: 0 1px 3px 0 #e6ebf1;
            -webkit-transition: box-shadow 150ms ease;
            transition: box-shadow 150ms ease;
        }

        .StripeElement--focus {
            box-shadow: 0 1px 3px 0 #cfd7df;
        }

        .StripeElement--invalid {
            border-color: #fa755a;
        }

        .StripeElement--webkit-autofill {
            background-color: #fefde5 !important;
        }
    }

</style>

<template>
  <div v-if="!isEducational" id="purchase" class="row justify-content-center ">
            <div class="col-md-12 col-lg-8 col-xl-7">
                <div class="card">
                    <div class="card-body p-4">
                        <div class="text-center w-75 m-auto">

                            <span><img src="@assets/images/athlisis-logo.svg" alt="" height="55"/></span>

                        </div>
                        <div v-if="!showPurchaseForm" class="first-step">
                            <div class="text-center w-75 m-auto">
                                <h2 class="text-muted mb-4 mt-3">Ευχαριστούμε για την εγγραφή σας στο athlisis!</h2>
                                <p class="text-muted mb-4 mt-3">Ολοκληρώστε την παραγγελία σας για να χρησιμοποιήσετε την εφαρμογή!</p>
                            </div>
                            <div class="mt-3 text-center">
                                <b-button variant="primary" class="btn-block mt-3" @click="displayPurchaseForm"><i class="icon-wallet"></i> Ολοκλήρωση παραγγελίας</b-button>
                            </div>
                        </div>

                        <div v-if="showPurchaseForm && price" class="p-3 text-center">
                            <p class="font-16">Αρχική τιμή: <del>{{price}}€</del>
                            </p><h2>Εκπτωτική τιμή: {{salePrice}}€</h2>
                            <p class="text-muted">Φ.Π.Α 24%: {{salePrice * 0.24}}€</p>
                            <b-overlay :show="order.submitted">
                                <div class="container-payment pt-3 mt-4 ">
                                    <label for="instalments">Πληρωμή:</label>
                                    <b-form-radio-group id="instalments" v-model="order.instalments">
                                        <b-form-radio value="1">Ολόκληρο το ποσό</b-form-radio>
                                        <b-form-radio value="2">2 Δόσεις*</b-form-radio>
                                    </b-form-radio-group>


                                  <div class="pt-3">
                                    <label for="invoice">Αποδεικτικό:</label>
                                    <b-form-radio-group id="invoice" v-model="order.invoice">
                                      <b-form-radio :value="false">Απόδειξη</b-form-radio>
                                      <b-form-radio :value="true">Τιμολόγιο</b-form-radio>
                                    </b-form-radio-group>
                                  </div>


                                    <div v-if="order.invoice" class="mt-4 bg-light rounded p-2">
                                      <div class="row">
                                        <div class="col-md-12">
                                          <p>
                                            Παρακαλώ εισάγετε τα στοιχεία τιμολόγησής σας για την έκδοση του τιμολογίου σας.<br>
                                            Εάν είστε επαγγελματίας στην Κύπρο, μην ξεχάσετε να βάλετε το πρόθεμα (CY).
                                          </p>
                                        </div>
                                        <div class="col-md-6">
                                          <b-form-group id="input-4" label="Α.Φ.Μ*" label-for="VAT">
                                            <b-form-input id="VAT" v-model="userCompany.VAT" type="text" placeholder="Εισάγετε τον Α.Φ.Μ. σας" ></b-form-input>
                                          </b-form-group>
                                        </div>
                                        <div class="col-md-6">
                                          <b-form-group id="input-7" label="Δ.Ο.Υ." label-for="DOY" >
                                            <b-form-input id="DOY" v-model="userCompany.DOY" type="text" placeholder="Εισάγετε τη Δ.Ο.Υ. σας" ></b-form-input>
                                          </b-form-group>
                                        </div>

                                        <div class="col-md-6">
                                          <b-form-group id="input-1" label="Επωνυμία εταιρείας*" label-for="companyName" >
                                            <b-form-input id="companyName" v-model="userCompany.companyName" type="text" placeholder="Εισάγετε την επωνυμία της εταιρείας σας" ></b-form-input>
                                          </b-form-group>
                                        </div>
                                        <div class="col-md-6">
                                          <b-form-group id="input-2" label="Επάγγελμα" label-for="occupation">
                                            <b-form-input id="occupation" v-model="userCompany.occupation" type="text" placeholder="Εισάγετε το επάγγελμά σας (τομέας δραστηριότητας της εταιρείας)" ></b-form-input>
                                          </b-form-group>
                                        </div>
                                      </div>

                                      <div class="row">
                                        <div class="col-md-6">
                                          <b-form-group id="input-6" label="Κινητό Τηλέφωνο*" label-for="mobile">
                                            <b-form-input id="mobile" v-model="userCompany.mobile" type="text" placeholder="Εισάγετε το κινητό σας" ></b-form-input>
                                          </b-form-group>
                                        </div>
                                        <div class="col-md-6">
                                          <b-form-group id="input-3" label="Διεύθυνση*" label-for="address" >
                                            <b-form-input id="address" v-model="userCompany.address" type="text" placeholder="Εισάγετε την διεύθυνση της εταιρείας σας" ></b-form-input>
                                          </b-form-group>
                                        </div>

                                      </div>
                                    </div>


                                    <card ref="stripeCard" class='stripe-card p-4' :class='{ completed }' :stripe="stripeKey" :options='stripeOptions' @change='completed = $event.complete'/>
                                    <b-button variant="primary" class='pay-with-stripe' :disabled='!completed || !invoiceDetailsFilled' @click='makePayment'><i class="icon-lock"></i> Ολοκλήρωση πληρωμής {{salePrice / order.instalments * 1.24}}€</b-button>

                                    <b-alert variant="danger" :show="order.error !== ''" class="mt-3">{{order.error}}</b-alert>
                                </div>
                               <b-input-group id="coupon" label="Κωδικός κουπονιού" label-for="coupon" class="pt-3">
                                   <b-input-group-prepend><b-button variant="success" :disabled="order.hasCoupon"><i class="icon-check"></i></b-button></b-input-group-prepend>
                                    <b-form-input id="coupon" v-model="order.couponCode" type="text"
                                                  required placeholder="Κωδικός κουπονιού" :disabled="order.hasCoupon" @change="getPrice"></b-form-input>

                                </b-input-group>
                                <b-alert variant="success" :show="order.hasCoupon">Ο κωδικός κουπονιού ενεργοποιήθηκε επιτυχώς.</b-alert>
                                <p v-if="parseInt(order.instalments) === 2" class="text-muted pt-3">*Η εξόφληση της 2ης δόσης πρέπει να ολοκληρωθεί σε 90 ημέρες</p>

                            </b-overlay>
                        </div>


                        <div class="logout text-center">
                            <hr>
                            Συνδεδεμένος ως <strong>{{user.firstName + ' ' + user.lastName }}</strong> (email: <strong>{{user.email}}</strong>)
                            <div><b-button variant="link" to="/logout">Αποσύνδεση;</b-button></div>
                        </div>
                    </div>

                    <!-- end card-body -->
                </div>
                <!-- end card -->
            </div>
            <!-- end col -->
    </div>
</template>
